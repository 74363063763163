<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="polls"
      theme="nocturnal"
     
    >
       <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item  v-on:click="peakPoll(props.row.id)">
                <feather-icon
                  icon="PlayIcon"
                  class="mr-50"
                />
                <span>Ver resultado</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] == null ? 'Sem registro' : props.formattedRow[props.column.field] }}
        </span>
      </template>
      
    </vue-good-table>
    
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import {
   BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown, 
    BDropdownItem,
    VueGoodTable
  },
  data () {
    return {
      columns: [
      {
        label: 'ID',
        field: 'id',
      },
      {
        label: 'Pergunta',
        field: 'question',
      },
      {
        label: 'Data de início',
        field: 'starts_at',
      },
      {
        label: 'Data de final',
        field: 'ends_at',
      },
      {
        label: 'Ações',
        field: 'actions',
      }
      ],
      polls: [],
    }
  },
  created() {
     this.getPolls();
  },
  methods: {
    getPolls(){
      this.$http.get('/polls/admin/get-prev-polls')
     .then(res => {
       this.polls = res.data
       
      })
    },
    peakPoll(pollId){
      // console.log(pollId);
      this.$router.push('/poll/poll-summary/' + pollId);
    }
  }
}
</script>

<style>

</style>
